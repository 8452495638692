import { Icon } from '@iconify/react';
import React from 'react';
import SectionHeading from './SectionHeading';

export default function Service({ data }) {
  const { sectionHeading, allService } = data;

  const splitIntoRows = (services, itemsPerRow) => {
    const rows = [];
    for (let i = 0; i < services.length; i += itemsPerRow) {
      rows.push(services.slice(i, i + itemsPerRow));
    }
    return rows;
  };

  const rows = splitIntoRows(allService, 3); // Split services into rows of 3

  return (
    <section className="section" id="services">
      <div className="container">
        {/* Original Section Heading */}
        <SectionHeading
          miniTitle={sectionHeading.miniTitle}
          title={sectionHeading.title}
        />
        {/* First Row */}
        <div className="row gy-5">
          {rows[0]?.map((item, index) => (
            <div className="col-sm-6 col-lg-4" key={index}>
              <div
                className="services-box"
                style={{ backgroundImage: `url(${item.imgUrl})`, height: '100%', width: '100%' }}
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay={index * 100}
              >
                <div className="services-body d-flex flex-column justify-content-between">
                  <div>
                    <div className="icon">
                      <Icon icon={item.icon} />
                    </div>
                    <h5>{item.title}</h5>
                    <p>{item.subTitle}</p>
                    <p>Authors: {item.authors}</p>
                  </div>
                  <div>
                    <button 
                      className="btn btn-primary"
                      onClick={() => window.open(item.viewLink, '_blank')}
                    >
                      View PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Section Heading for Certifications */}
        <SectionHeading
          miniTitle="Certifications"
          title="Certifications "
        />
        {/* Second Row */}
        <div className="row gy-5">
          {rows[1]?.map((item, index) => (
            <div className="col-sm-6 col-lg-4" key={index}>
              <div
                className="services-box"
                style={{ backgroundImage: `url(${item.imgUrl})`, height: '100%', width: '100%' }}
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay={index * 100}
              >
                <div className="services-body d-flex flex-column justify-content-between">
                  <div>
                    <div className="icon">
                      <Icon icon={item.icon} />
                    </div>
                    <h5>{item.title}</h5>
                    <p>{item.subTitle}</p>
                    <p>Description:{item.Description}</p>
                    <p>Type: {item.Type}</p>
                    <p>Issuer:{item.Issuer}</p>
                  </div>
                  
                  <div>
                    <button 
                      className="btn btn-primary"
                      onClick={() => window.open(item.viewLink, '_blank')}
                    >
                      View Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
